import React from "react";
import styled from "styled-components";

export default function ChineseAppleInstructions() {
  return (
    <StyledWrapper>
      <div className="container">
        <h1>Vennchat (万通) IOS 中国手机安装</h1>

        <hr />

        <div className="section">
          <h2>1. 在APP商店下载TestFlight</h2>
          <img width="100%" src="/images/testflight.png" alt="testflight" />
        </div>

        <div className="section">
          <h2>2. 点击链接下载并安装</h2>
          <a href="https://testflight.apple.com/join/ea5DVFj2">
            https://testflight.apple.com/join/ea5DVFj2
          </a>
        </div>
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  background-color: #130f3f;
  padding: 20px;
  min-height: 100vh;
  color: #fff;

  .section {
    margin: 20px;
    div {
      font-size: 14px;
      font-weight: 400;
      padding: 10px 0px;
    }
  }
  h1 {
    font-size: 28px;
    margin: 20px 0px;
  }
  h2 {
    font-size: 20px;
    font-weight: 400;
    margin: 40px 0px;
  }
  a {
    color: #d995fa;
  }
`;
